import React, { CSSProperties } from "react"

interface IProps {
  size: number
  color1?: string
  color2?: string
  className?: string
  style?: CSSProperties
}

export default function Circles({
  size = 24,
  color1 = "#1956DB",
  color2 = "#1956DB",
  className,
  style,
}: IProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 480 235">
      <ellipse
        cx="117.073"
        cy="117.073"
        rx="117.073"
        ry="117.073"
        fill={color1}
      />
      <circle cx="362.927" cy="117.073" r="117.073" fill={color2} />
    </svg>
  )
}
