// custom typefaces
import "typeface-merriweather"
import "typeface-poppins"

// TailwindCSS
// Read more on how to add other base styles https://tailwindcss.com/docs/adding-base-styles
// Extracting components https://tailwindcss.com/docs/extracting-components
// Or adding new utilities https://tailwindcss.com/docs/adding-new-utilities
import "tailwindcss/base.css"
import "tailwindcss/components.css"
import "tailwindcss/utilities.css"

import "prismjs/themes/prism.css"
// import "./src/css/index.css"
import "./src/css/markdown.css"
import "prismjs/themes/prism.css"