import React from "react"
import { Link } from "gatsby"
import { rhythm, scale } from "../utils/typography"
import Logo from "./Logo"
import Circles from "./Circles"
import Segment from "./Segment"

interface IProps {
  location: any
  title: string
  children: any
}

const Layout = ({ location, title, children }: IProps) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <div className="mb-8 flex justify-center flex-col items-center">
        <a
          href="https://preferr.app"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none !important",
            boxShadow: "none",
          }}
        >
          <Logo width={140} style={{ marginBottom: "1rem" }}></Logo>
        </a>
        <div style={{ textAlign: "center", marginBottom: rhythm(0.5) }}>
          <h1
            className="text-gray-800"
            style={{
              ...scale(1.2),
              marginTop: 0,
              marginBottom: "1.5rem",
              fontFamily: `Poppins, sans-serif`,
              fontWeight: 600,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
              fontFamily: "Poppins",
            }}
            to={`/`}
          >
            The Preferr blog
          </Link>
        </div>
      </div>
    )
  } else {
    header = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <a
          href="https://preferr.app"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none !important",
            boxShadow: "none",
          }}
        >
          <Logo width={140} style={{ marginBottom: "1rem" }}></Logo>
        </a> */}
        <h3
          className="text-xl text-gray-800"
          style={{
            fontFamily: `Poppins, sans-serif`,
            marginTop: 0,
            marginBottom: 0,
            fontWeight: 600,
            marginRight: "0.5rem",
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              color: `inherit`,
              marginBottom: "0 !important",
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
        <Circles size={30} color1="#2C9CDB"></Circles>
      </div>
    )
  }
  return (
    <div
      className="min-h-screen"
      style={{
        // marginLeft: `auto`,
        // marginRight: `auto`,
        // maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        height: "100%",
        // background: "linear-gradient(120deg, #1c64f2 5%, #f9fafb 55%)",
        // backgroundSize: "120% 120%",
      }}
    >
      <header>{header}</header>
      <main
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
        }}
      >
        {children}
      </main>
      <footer
        className="mt-12"
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
        }}
      >
        {/* <Segment opaque> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a
            href="https://preferr.app"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none !important",
              boxShadow: "none",
            }}
          >
            <Logo width={120}></Logo>
          </a>
          <span>
            © {new Date().getFullYear()}, Built with{" "}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </span>
        </div>
        {/* </Segment> */}
      </footer>
    </div>
  )
}

export default Layout
