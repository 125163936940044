import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Segment from "../components/Segment"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Preferrences - All posts" />
      {/* <Bio /> */}
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <Segment className="mb-8" shadow={"shadow-md"}>
            <article
              key={node.fields.slug}
              style={{ marginBottom: rhythm(1) }}
              className="my-2"
            >
              <header>
                <h3 className="mb-4 text-2xl text-blue-600 hover:text-blue-800 ">
                  <Link
                    style={{
                      boxShadow: `none`,
                      fontFamily: "Poppins",
                      fontWeight: 600,
                    }}
                    to={node.fields.slug}
                  >
                    {title}
                  </Link>
                </h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.9rem",
                    marginBottom: "0.3rem",
                  }}
                >
                  <small>
                    {node.frontmatter.author} - {node.frontmatter.date}
                  </small>
                </div>
              </header>
              <section>
                <p
                  className="mt-4"
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          </Segment>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
          }
        }
      }
    }
  }
`
